.logoContainer {
  background: rgba(255, 255, 255, .65);
  padding: 20px;
  text-align: center;

  img {
    width: 400px;
    max-width: 100%;
  }
}
