.adminMenu {
  position: fixed;
  left: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 25px;
  transition: bottom 150ms linear;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);

  &.opened {
    bottom: 30px !important;
  }

  .item {
    cursor: pointer;
    padding: 20px;
    font-size: 1.1em;
    text-align: center;

    &.inactive {
      color: #888;
    }
  }
}
