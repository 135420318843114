@import './colors';
@import './fonts';
@import './globals';
@import './mixins';

body {
  margin: 0;
  padding: 0;
  font: 16px / 1.6 $main-font;
  background: $background-fallback;
  background-image: url('/images/background-fallback.png');
  background-image: url('/images/background.png'), linear-gradient($background-gradient-start, $background-gradient-end);
  background-attachment: fixed;
  overflow-x: hidden;
  padding-bottom: 2em;

  @include mobile-break {
    padding-bottom: 0;
  }

  @media only screen and ( max-width: 1420px ) {
    padding-bottom: 0;
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $accent;
  cursor: pointer;

  &:hover {
    color: lighten($accent, 15%);
  }
}

h1, h2, h3, h4, h5 {
  font-family: $heading-font;
  font-weight: 300;
  text-align: center;
  margin: 1rem 0;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.5em;
}

::selection {
  color: #fff;
  background: $accent;
}

img {
  max-width: 100%;
}

hr {
  border: 0;
  height: 1px;
  width: 90%;
  background: $accent;
  margin: 2em auto;
}

.input-group {
  margin-bottom: 1.5em;

  &.buttons {
    text-align: center;
  }
}

input, textarea {
  border: 1px solid $accent;
  padding: .6em 1.2em;
  font: 16px / 1.6 $main-font;
  outline: none;
  display: block;
  width: 100%;

  transition: 150ms background linear;

  &:focus {
    background: lighten($accent, 45%);
  }

  &[type=checkbox] {
    display: inline-block;
    width: auto;
    margin-right: 0.5em;
  }
}

button {
  background: $accent;
  color: #fff;
  border: 0;
  padding: 1em 2em;
  font: 16px $main-font;
  font-weight: 700;
  cursor: pointer;
  outline: none;

  transition: 150ms background linear;

  &:hover {
    background: lighten($accent, 10%);
  }

  .multiple-buttons & {
    margin: 0.4em;
  }

  svg {
    margin-right: 0.5em;
  }
}

.multiple-buttons {
  margin-bottom: 1em;
  text-align: center;
}

textarea {
  resize: none;
  height: 200px;
}

label {
  font-weight: 700;
  text-transform: uppercase;

  small {
    text-transform: none;
  }
}

.mid {
  width: $desktop-width;
  margin: 0 auto;

  @include tablet-break {
    width: $tablet-width;
  }

  @include mobile-break {
    width: auto;
  }
}

.page {
  background: #fff;
  padding: 1em 2em;
  margin-top: 2em;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .3);

  @include mobile-break {
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &.large {
    width: 80vw;
  }
}

.columns {
  display: flex;

  .column {
    flex: 1;
    padding: 10px;
  }

  @include mobile-break {
    display: block;

    .column {
      padding: 0;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.messagebox {
  padding: 1em 1.2em;
  color: #fff;
  margin-bottom: 1em;

  &.error {
    background: $danger;
  }

  &.success {
    background: $success;
  }

  &.info {
    background: $info;
  }
}

.youtube-wrapper {
  width: 100%;
  margin: 0;

  .youtube-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

footer {
  font-size: .9em;
  text-align: center;
  margin-top: 2em;
  color: #565656;

  &::before {
    content: '';
    display: block;
    width: 75%;
    height: 1px;
    margin: 0 auto;
    margin-bottom: 2em;
    background: $accent;
  }
}

.corner-image {
  position: fixed;
  bottom: 0;
  right: 0;
  line-height: 0;
  max-width: 500px;
  width: 25vw;

  img {
    position: relative;
    z-index: 10;
    pointer-events: none;
    user-select: none;
  }

  @media only screen and ( max-width: 1760px ) {
    width: 20vw;
  }

  @media only screen and ( max-width: 1420px ) {
    position: static;
    width: auto;
    max-width: 100%;
    text-align: center;
    background: #fff;

    img {
      width: 300px;
    }
  }
}
