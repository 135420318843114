@import 'colors';
@import 'mixins';

$transition-duration: 130ms;

.mainMenu {
  background: $accent;
  color: #fff;

  .items {
    text-align: center;

    a {
      display: inline-block;
      color: #fff;
      font-size: 1.2em;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      transition: background $transition-duration linear;

      span {
        display: inline-block;
        padding: 0.8rem 2rem;

        &::after {
          content: '';
          display: block;
          height: 2px;
          background: #fff;
          width: 0;
          transition: width $transition-duration linear;
          margin: 0 auto;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, .2);

        span {
          &::after {
           width: 100%;
          }
        }
      }

      &.active {
        span {
          &::after {
            width: 100%;
          }
        }
      }

      @include tablet-break {
        font-size: 1em;
      }

      @include mobile-break {
        display: block;
      }
    }

    @include mobile-break {
      display: none;
    }

    &.active {
      display: block;
    }
  }

  .hamburgerToggler {
    padding: 0.7em;
    text-align: center;
    font-size: 1.2em;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    display: none;

    svg {
      margin-right: 1em;
    }

    &.active {
      background: rgba(0, 0, 0, .2);
    }

    @include mobile-break {
      display: block;
    }
  }
}
