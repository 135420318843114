// Leckerli One

@font-face {
  font-family: 'leckerli-one';
  src: url('./fonts/leckerli-one/leckerli-one-regular.woff2') format('woff2'),
       url('./fonts/leckerli-one/leckerli-one-regular.woff') format('woff'),
       url('./fonts/leckerli-one/leckerli-one-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Roboto

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto/roboto-regular.woff2') format('woff2'),
       url('./fonts/roboto/roboto-regular.woff') format('woff'),
       url('./fonts/roboto/roboto-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto/roboto-700.woff2') format('woff2'),
       url('./fonts/roboto/roboto-700.woff') format('woff'),
       url('./fonts/roboto/roboto-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

$main-font: 'roboto', sans-serif;
$heading-font: 'leckerli-one', cursive;
